import axios from "axios";
import { observer } from "mobx-react";
import React, { Component } from "react";
import ClearCache from "react-clear-cache";
import CookieConsent from "react-cookie-consent";
import GlitchText from "react-glitch-effect/core/GlitchText";
import InstagramFeed from "react-ig-feed";
import "react-ig-feed/dist/index.css";
import Lightbox from "react-image-lightbox";
import { Link, NavLink, withRouter } from "react-router-dom";
import { StageSpinner } from "react-spinners-kit";
import Strapi from "strapi-sdk-javascript/build/main";
import { productsStore } from "../productsStore";
import {
  calculateAmount,
  clearUser,
  getCart,
  getLanguageBarCookie,
  getLanguageFromBrowser,
  getLanguageFromCookie,
  getLanguageFromParams,
  hasProductsForLanguage,
  jegerbueId,
  setCart,
  setLanguageBarCookie,
  setLanguageCookie,
  showSizeGuideButton,
  translate,
  validEmailAddress,
} from "../utils";
import { itemsInCart, setCampaignLabel } from "../utils/cartFunctions";
import {
  dummyProduct,
  showRankPatches,
  showTestProduct,
  testProductAdhesive,
  testProductRankPatch,
} from "../utils/dummyProducts";
import {
  getHeroProduct,
  instagramToken,
  setHeroContent,
  spaceToDash,
} from "../utils/helperFunctions";
import { FREE_SHIPPING_NORWAY } from "../utils/translations";
import "./App.scss";
import Footer from "./Footer";
import "./GalleryModal.scss";
import HeroLinks from "./HeroLinks";
import Modal from "./Modal";
import Navbar from "./Navbar";
const isProduction = process.env.NODE_ENV === "production";
const strapiUrl = isProduction
  ? "https://sjanten-server.herokuapp.com"
  : "http://localhost:1337";
const strapi = new Strapi(strapiUrl);

class _App extends Component {
  state = {
    bigPatches: [],
    smallPatches: [],
    gadgets: [],
    tShirts: [],
    rankPatches: [],
    adhesives: [],
    watches: [],
    featuredProducts: [],
    campaigns: [],
    campaignToDisplay: undefined,
    loadingProducts: true,
    loadingBigPatches: true,
    loadingSmallPatches: true,
    loadingGadgets: true,
    loadingTshirts: true,
    loadingRankPatches: true,
    loadingAdhesives: true,
    loadingWatches: true,
    loadingFeaturedProducts: true,
    featuredProductToDisplay: undefined,
    cartItems: [],
    showMobileCart: false,
    showAgreement: false,
    itemAddedToCart: false,
    websiteLanguage: getLanguageFromBrowser(),
    showOfferPrice: false,
    productSpecSelected: "big",
    showTestProducts: false,
    customOrder: false,
    showNotifyMeForm: false,
    notifyMeItem: undefined,
    notifyMeEmailAddress: "",
    emailValidationError: undefined,
    sendingNotifyMeEmail: false,
    notifyMeSubmitted: false,
    notifyMeSubmittedItem: "",
    sizeSelected: undefined,
    sizesSelected: [],
    sizeSelectedModal: undefined,
    selectedProductSizes: undefined,
    modalOpen: false,
    imageModalOpen: false,
    imageModalIndex: 0,
    galleryImages: [],
    imageModalItem: undefined,
    showSearchField: false,
    searchString: "",
    rankPatchImage: undefined,
    smallPatchImage: undefined,
    rankPatchImageCoyote: undefined,
    heroProduct: undefined,
    clientLocation: undefined,
    hideLanguageBar: true,
    qualificationDisclaimerAccepted: false,
    loadingScrollToProduct: false,
    adsRedirect: false,
    variantsSelected: [],
    productsFromStore: null,
  };

  async componentDidMount() {
    document.querySelector("html").setAttribute("style", "overflow: visible;");
    clearUser();
    if (!this.state.heroProduct) {
      const heroProduct = getHeroProduct();
      this.setState({ heroProduct: heroProduct });
    }
    if (getLanguageFromParams() === "no") {
      this.setState({ websiteLanguage: "no", adsRedirect: true });
    } else if (getLanguageFromCookie()) {
      if (getLanguageFromCookie() === "no") {
        this.setState({ websiteLanguage: "no" });
      } else if (getLanguageFromCookie() === "sv") {
        this.setState({ websiteLanguage: "sv" });
      } else if (getLanguageFromCookie() === "da") {
        this.setState({ websiteLanguage: "da" });
      } else {
        this.setState({ websiteLanguage: "en" });
      }
    } else {
      if (getLanguageFromBrowser() === "no") {
        this.setState({ websiteLanguage: "no" });
      } else if (getLanguageFromBrowser() === "sv") {
        this.setState({ websiteLanguage: "sv" });
      } else if (getLanguageFromBrowser() === "da") {
        this.setState({ websiteLanguage: "da" });
      } else {
        this.setState({ websiteLanguage: "en" });
      }
    }
    if (document.querySelector("#root").classList.contains("checkout")) {
      document.querySelector("#root").classList.remove("checkout");
    }
    if (document.querySelector("#root").classList.contains("cart-visible")) {
      document.querySelector("#root").classList.remove("cart-visible");
      document.querySelector("#root").classList.add("cart-hidden");
    }
    if (window.location.search.includes("testproducts")) {
      let params = new URL(window.location).searchParams;
      let testproduct = params.get("testproducts");
      if (testproduct === "true") {
        this.setState({ showTestProducts: true });
      }
    }
    this.setState({
      cartItems: getCart(),
      hideLanguageBar: getLanguageBarCookie(),
    });
    this.setBgImage();

    if (window.location.search.includes("custom_order")) {
      let params = new URL(window.location).searchParams;
      let customOrder = params.get("custom_order");
      if (customOrder === "true") {
        const order = params.get("order").replace(/_/g, " ");
        const price = params.get("price");
        const customOrder = {
          _id: "customOrder",
          description: order,
          englishDescription: order,
          image: {
            url: "https://www.3charlie.no/icons/3charlie.svg",
          },
          itemsLeft: 100,
          label: "",
          name: order,
          price: price,
          packageSize: "large",
          weightInKilos: 0.75,
        };
        this.setState({ customOrder: true }, () =>
          this.handleAddToCart(customOrder, false)
        );
      }
    } else if (window.location.href.includes("#")) {
      this.setState({ loadingScrollToProduct: true });
    }

    const campaignsFromStore = productsStore.allCampaigns;
    if (campaignsFromStore && campaignsFromStore.length !== 0) {
      this.setState({
        campaigns: campaignsFromStore[0],
        campaignToDisplay: this.setCampaignToDisplay(campaignsFromStore[0]),
      });
    } else {
      try {
        const campaignsResponse = await strapi.request("POST", "/graphql", {
          data: {
            query: `query {
              campaigns {
                _id
                titleNorwegian
                titleEnglish
                titleSwedish
                shortcut
                enabled
              }
            }
            `,
          },
        });
        const campaigns = campaignsResponse.data.campaigns;
        this.setState({
          campaigns,
          campaignToDisplay: this.setCampaignToDisplay(campaigns),
        });
        productsStore.addCampaigns(campaigns);
      } catch (err) {
        console.log("could not load campaigns:", err);
      }
    }

    const productsFromStore = productsStore.allProducts;
    if (productsFromStore && productsFromStore.length !== 0) {
      const featuredProducts = productsFromStore.find(
        (product) => product.category === "featuredproducts"
      ).products;
      this.setState({
        loadingBigPatches: false,
        bigPatches: productsFromStore.find(
          (product) => product.category === "patches"
        ).products,
        loadingSmallPatches: false,
        smallPatches: productsFromStore.find(
          (product) => product.category === "smallpatches"
        ).products,
        loadingGadgets: false,
        gadgets: productsFromStore.find(
          (product) => product.category === "gadgets"
        ).products,
        loadingTshirts: false,
        tShirts: productsFromStore.find(
          (product) => product.category === "tshirts"
        ).products,
        loadingRankPatches: false,
        rankPatches: productsFromStore.find(
          (product) => product.category === "rankpatches"
        ).products,
        loadingWatches: false,
        watches: productsFromStore.find(
          (product) => product.category === "watches"
        ).products,
        loadingAdhesives: false,
        adhesives: productsFromStore.find(
          (product) => product.category === "adhesives"
        ).products,
        loadingProducts: false,
        featuredProducts: featuredProducts,
        loadingFeaturedProducts: false,
        featuredProductToDisplay: this.setFeaturedProduct(featuredProducts),
      });
    } else {
      try {
        const rankpatchesResponse = await strapi.request("POST", "/graphql", {
          data: {
            query: `query {
              rankpatches(sort: "name") {
                _id
                name
                description
                englishDescription
                swedishDescription
                danishDescription
                price
                itemsLeft
                label
                image {
                  url
                }
                weightInKilos
                enabled
                imageGallery {
                  url
                }
                variants
                featured
                packageSize
                exportShippingInfo
                fullProductTitle
                productDataLink
              }
            }
            `,
          },
        });
        let rankPatches = rankpatchesResponse.data.rankpatches
          ? rankpatchesResponse.data.rankpatches
          : [];

        if (showTestProduct && !isProduction) {
          if (rankPatches) {
            rankPatches = rankPatches.concat(testProductRankPatch);
          } else {
            rankPatches = testProductRankPatch;
          }
        }
        this.setState({
          loadingRankPatches: false,
          rankPatches: rankPatches && rankPatches,
        });
        productsStore.addProducts({
          category: "rankpatches",
          products: rankPatches,
        });

        const tshirtsResponse = await strapi.request("POST", "/graphql", {
          data: {
            query: `query {
              tshirts(sort: "name") {
                _id
                name
                description
                englishDescription
                swedishDescription
                danishDescription
                price
                itemsLeft
                label
                image {
                  url
                }
                sizes
                weightInKilos
                enabled
                imageGallery {
                  url
                }
                variants
                featured
                showSizeGuideButton
                sizeGuide
                fullProductTitle
                productDataLink
                packageSize
                exportShippingInfo
              }
            }
            `,
          },
        });
        this.setState({
          loadingTshirts: false,
          tShirts: tshirtsResponse.data.tshirts,
        });
        productsStore.addProducts({
          category: "tshirts",
          products: tshirtsResponse.data.tshirts,
        });

        const watchesResponse = await strapi.request("POST", "/graphql", {
          data: {
            query: `query {
              watches(sort: "name") {
                _id
                name
                description
                englishDescription
                swedishDescription
                danishDescription
                price
                itemsLeft
                label
                image {
                  url
                }
                sizes
                weightInKilos
                enabled
                imageGallery {
                  url
                }
                variants
                featured
                showSizeGuideButton
                sizeGuide
                fullProductTitle
                productDataLink
                packageSize
                exportShippingInfo
              }
            }
            `,
          },
        });
        this.setState({
          loadingWatches: false,
          watches: watchesResponse.data.watches,
        });
        productsStore.addProducts({
          category: "watches",
          products: watchesResponse.data.watches,
        });

        const patchesResponse = await strapi.request("POST", "/graphql", {
          data: {
            query: `query {
              patches(sort: "name") {
                _id
                name
                description
                englishDescription
                swedishDescription
                danishDescription
                price
                itemsLeft
                label
                image {
                  url
                }
                weightInKilos
                enabled
                imageGallery {
                  url
                }
                variants
                featured
                packageSize
                exportShippingInfo
              } 
            }
            `,
          },
        });
        this.setState({
          loadingBigPatches: false,
          bigPatches: patchesResponse.data.patches,
        });
        productsStore.addProducts({
          category: "patches",
          products: patchesResponse.data.patches,
        });

        const smallpatchesResponse = await strapi.request("POST", "/graphql", {
          data: {
            query: `query {
              smallpatches(sort: "name") {
                _id
                name
                description
                englishDescription
                swedishDescription
                danishDescription
                price
                itemsLeft
                label
                image {
                  url
                }
                weightInKilos
                enabled
                imageGallery {
                  url
                }
                variants
                featured
                packageSize
                exportShippingInfo
              } 
            }
            `,
          },
        });
        this.setState({
          loadingSmallPatches: false,
          smallPatches: smallpatchesResponse.data.smallpatches,
        });
        productsStore.addProducts({
          category: "smallpatches",
          products: smallpatchesResponse.data.smallpatches,
        });

        const gadgetsResponse = await strapi.request("POST", "/graphql", {
          data: {
            query: `query {
              gadgets(sort: "name") {
                _id
                name
                description
                englishDescription
                swedishDescription
                danishDescription
                price
                itemsLeft
                label
                image {
                  url
                }
                weightInKilos
                enabled
                imageGallery {
                  url
                }
                variants
                featured
                packageSize
                exportShippingInfo
              }
            }
            `,
          },
        });
        this.setState({
          loadingGadgets: false,
          gadgets: gadgetsResponse.data.gadgets,
        });
        productsStore.addProducts({
          category: "gadgets",
          products: gadgetsResponse.data.gadgets,
        });

        const adhesivesResponse = await strapi.request("POST", "/graphql", {
          data: {
            query: `query {
              adhesives(sort: "name") {
                _id
                name
                description
                englishDescription
                swedishDescription
                danishDescription
                price
                itemsLeft
                label
                image {
                  url
                }
                weightInKilos
                enabled
                imageGallery {
                  url
                }
                variants
                featured
                packageSize
                exportShippingInfo
              }
            }
            `,
          },
        });
        let adhesives = adhesivesResponse.data.adhesives
          ? adhesivesResponse.data.adhesives
          : [];
        if (showTestProduct && !isProduction) {
          if (adhesives) {
            adhesives = adhesives.concat(testProductAdhesive);
          } else {
            adhesives = testProductAdhesive;
          }
        }

        const featuredProductsResponse = await strapi.request(
          "POST",
          "/graphql",
          {
            data: {
              query: `query {
              featuredproducts(sort: "name") {
                _id
                enabled
                title
                englishTitle
                swedishTitle
                danishTitle
                description
                englishDescription
                swedishDescription
                danishDescription
                readmoreLink
                imageUrlList
              }
            }
            `,
            },
          }
        );

        this.setState(
          {
            loadingFeaturedProducts: false,
            featuredProducts: featuredProductsResponse.data.featuredproducts,
            adhesives: adhesives && adhesives,
            loadingAdhesives: false,
            loadingProducts: false,
            loadingScrollToProduct: false,
            featuredProductToDisplay: this.setFeaturedProduct(
              featuredProductsResponse.data.featuredproducts
            ),
          },
          () => this.scrollToProductFromUrl()
        );
        productsStore.addProducts({
          category: "adhesives",
          products: adhesives,
        });
        productsStore.addProducts({
          category: "featuredproducts",
          products: featuredProductsResponse.data.featuredproducts,
        });
      } catch (err) {
        console.log("could not load all products:", err);
        this.setState({
          loadingProducts: false,
          loadingBigPatches: false,
          loadingSmallPatches: false,
          loadingGadgets: false,
          loadingTshirts: false,
          loadingRankPatches: false,
          loadingAdhesives: false,
        });
      }
    }

    this.setPreselectedSegment();
  }

  setBgImage = () => {
    let bgImages = [
      "banner-1.jpg",
      "banner-2.jpg",
      "banner-3.jpg",
      "banner-4.jpg",
      "banner-5.jpg",
      "banner-6.jpg",
      "banner-7.jpg",
      "banner-8.jpg",
      "banner-10.jpg",
      "banner-11.jpg",
      "banner-12.jpg",
      "banner-13.jpg",
      "banner-14.jpg",
      "banner-15.jpg",
      "banner-16.jpg",
      "banner-17.jpg",
      "banner-18.jpg",
      "banner-19.jpg",
      "banner-20.jpg",
    ];
    if (
      this.state.websiteLanguage === "da" ||
      this.state.websiteLanguage === "sv"
    ) {
      bgImages = [
        "banner-9.jpg",
        "banner-10.jpg",
        "banner-11.jpg",
        "banner-14.jpg",
        "banner-15.jpg",
      ];
    }
    const randomNumber = Math.floor(Math.random() * bgImages.length);
    const heroDiv = document.querySelector(".hero");
    if (heroDiv) {
      heroDiv.style.backgroundImage = `url(./icons/${bgImages[randomNumber]})`;
    }
  };

  switchLanguage = (targetLanguage) => {
    setLanguageCookie(targetLanguage);
    this.setState({ websiteLanguage: targetLanguage });
  };

  mapToCartSize = (selectedSize) => {
    if (selectedSize === "small") {
      return "(S)";
    } else if (selectedSize === "medium") {
      return "(M)";
    } else if (selectedSize === "large") {
      return "(L)";
    } else if (selectedSize === "xlarge") {
      return "(XL)";
    } else if (selectedSize === "OR2" || selectedSize === "0_OR2_COYOTE") {
      return "(OR2)";
    } else if (selectedSize === "OR3" || selectedSize === "6_OR3_COYOTE") {
      return "(OR3)";
    } else if (selectedSize === "OR4" || selectedSize === "7_OR4_COYOTE") {
      return "(OR4)";
    } else if (selectedSize === "OR4P" || selectedSize === "8_OR4P_COYOTE") {
      return "(OR4+)";
    } else if (selectedSize === "OR5" || selectedSize === "1_OR5_COYOTE") {
      return "(OR5)";
    } else if (selectedSize === "OR5P" || selectedSize === "2_OR5P_COYOTE") {
      return "(OR5+)";
    } else if (selectedSize === "OR6" || selectedSize === "9_OR6_COYOTE") {
      return "(OR6)";
    } else if (selectedSize === "OR6S" || selectedSize === "3_OR6S_COYOTE") {
      return "(OR6 Sjef)";
    } else if (selectedSize === "OR7") {
      return "(OR7)";
    } else if (selectedSize === "OR7S") {
      return "(OR7 Sjef)";
    } else if (selectedSize === "OR8") {
      return "(OR8)";
    } else if (selectedSize === "OR8S") {
      return "(OR8 Sjef)";
    } else if (selectedSize === "4_OF1_COYOTE") {
      return "(OF1)";
    } else if (selectedSize === "5_OF2_COYOTE") {
      return "(OF2)";
    } else if (selectedSize === "OR9") {
      return "(OR9)";
    } else if (selectedSize === "OR9S") {
      return "(OR9 Sjef)";
    } else if (selectedSize === "NS") {
      return "(Black)";
    } else if (selectedSize === "NG") {
      return "(Olive)";
    } else if (selectedSize === "KS") {
      return "(Black)";
    } else if (selectedSize === "KG") {
      return "(Olive)";
    } else if (selectedSize === "Black") {
      return "(Black)";
    } else if (selectedSize === "Olive") {
      return "(Olive)";
    } else if (selectedSize === "Coyote") {
      return "(Coyote)";
    } else if (selectedSize === "Multicam") {
      return "(Multicam)";
    } else if (selectedSize === "Normal") {
      return "(Normal)";
    } else if (selectedSize === "Reverse") {
      return "(Reverse)";
    } else if (selectedSize === "Aplus") {
      return "(A+)";
    } else if (selectedSize === "Aminus") {
      return "(A-)";
    } else if (selectedSize === "ABplus") {
      return "(AB+)";
    } else if (selectedSize === "ABminus") {
      return "(AB-)";
    } else if (selectedSize === "Bplus") {
      return "(B+)";
    } else if (selectedSize === "Bminus") {
      return "(B-)";
    } else if (selectedSize === "0plus") {
      return "(0+)";
    } else if (selectedSize === "0minus") {
      return "(0-)";
    } else if (selectedSize === "SM") {
      return "(S/M)";
    } else if (selectedSize === "LXL") {
      return "(L/XL)";
    } else {
      return null;
    }
  };

  getSelectedProductVariant = (product) => {
    let productHasVariants = false;
    // Check if product has variants
    if (product.variants && Object.keys(product.variants).length !== 0) {
      productHasVariants = true;
    }
    // Check if product has already been selected
    const productVariantSelectedIndex = this.state.variantsSelected.findIndex(
      (item) => item.productId === product._id
    );
    const productVariantAlreadySelected = productVariantSelectedIndex !== -1;
    if (productHasVariants && productVariantAlreadySelected) {
      return this.state.variantsSelected[productVariantSelectedIndex].variant;
    } else {
      return undefined;
    }
  };

  setSelectedProductVariant = (selectedVariant, product, productVariants) => {
    const variantIndex = productVariants.findIndex(
      (item) => item[0] === selectedVariant
    );
    const variantCartName = productVariants[variantIndex][1].cartName;
    const variantImageUrl = productVariants[variantIndex][1].imageUrl;
    const variantInStock = productVariants[variantIndex][1].inStock;

    let variantAlreadySelected = undefined;
    let updatedVariantsSelected = undefined;
    const productName = product.name;
    const productId = product._id;
    const variantsSelected = this.state.variantsSelected;
    variantAlreadySelected = variantsSelected.findIndex(
      (item) => item.productId === productId
    );
    if (variantAlreadySelected === -1) {
      updatedVariantsSelected = variantsSelected.concat({
        ...{
          productId: productId,
          productVariantName: `${productName} ${variantCartName}`,
          variant: selectedVariant,
          variantImageUrl: variantImageUrl,
          variantInStock: variantInStock,
        },
      });
      this.setState({ variantsSelected: updatedVariantsSelected });
    } else {
      variantsSelected[variantAlreadySelected] = {
        productId: productId,
        productVariantName: `${productName} ${variantCartName}`,
        variant: selectedVariant,
        variantImageUrl: variantImageUrl,
        variantInStock: variantInStock,
      };
      updatedVariantsSelected = variantsSelected;
      this.setState({ variantsSelected: updatedVariantsSelected });
    }
  };

  handleAddToCart = (product, adding) => {
    let alreadyInCart = false;
    let sizedProduct = undefined;

    // google tag manager
    window.gtag("event", "conversion_event_add_to_cart", {});

    if (
      product._id === jegerbueId &&
      !this.state.qualificationDisclaimerAccepted
    ) {
      this.setState(
        {
          modalOpen: true,
          selectedProductInSizeModal: product,
        },
        () => {
          document
            .querySelector("html")
            .setAttribute("style", "overflow: hidden;");
        }
      );
      return;
    }

    // Check if product has variants
    let productHasVariants = false;
    if (product.variants && Object.keys(product.variants).length !== 0) {
      productHasVariants = true;
    }

    if (productHasVariants) {
      sizedProduct = { ...product };
      if (adding) {
        sizedProduct.name = product.name;
      } else {
        const variantsSelected = this.state.variantsSelected;
        const selectedVariantIndex = variantsSelected.findIndex(
          (item) => item.productId === product._id
        );
        sizedProduct.name =
          variantsSelected[selectedVariantIndex].productVariantName;
      }
      alreadyInCart = this.state.cartItems.findIndex(
        (item) => item.name === sizedProduct.name
      );
    } else {
      alreadyInCart = this.state.cartItems.findIndex(
        (item) => item._id === product._id
      );
    }

    if (alreadyInCart === -1) {
      let updatedItems = undefined;
      if (productHasVariants) {
        updatedItems = this.state.cartItems.concat({
          ...sizedProduct,
          quantity: 1,
        });
      } else {
        updatedItems = this.state.cartItems.concat({
          ...product,
          quantity: 1,
        });
      }
      this.setState({ cartItems: updatedItems }, () => setCart(updatedItems));
    } else if (product._id !== "customOrder") {
      const updatedItems = [...this.state.cartItems];
      updatedItems[alreadyInCart].quantity += 1;
      this.setState({ cartItems: updatedItems }, () => setCart(updatedItems));
    }
    this.updateCartBadge();
  };

  removeItemFromCart = (product) => {
    let alreadyInCart = undefined;

    if (product.sizes || product.types) {
      alreadyInCart = this.state.cartItems.findIndex(
        (item) => item._id === product._id && item.name === product.name
      );
    } else {
      alreadyInCart = this.state.cartItems.findIndex(
        (item) => item._id === product._id
      );
    }

    const updatedItems = [...this.state.cartItems];

    if (updatedItems[alreadyInCart].quantity > 1) {
      updatedItems[alreadyInCart].quantity -= 1;
      this.setState({ cartItems: updatedItems }, () => setCart(updatedItems));
    } else {
      this.deleteItemFromCart(product);
    }
    this.updateCartBadge();
  };

  updateCartBadge() {
    this.setState({ itemAddedToCart: true });
    setTimeout(() => {
      this.setState({ itemAddedToCart: false });
    }, 300);
  }

  deleteItemFromCart = (product) => {
    let filteredItems = undefined;
    if (product.sizes || product.types) {
      filteredItems = this.state.cartItems.filter(
        (item) => item.name !== product.name
      );
    } else {
      filteredItems = this.state.cartItems.filter(
        (item) => item._id !== product._id
      );
    }
    this.setState(
      {
        cartItems: filteredItems,
        showMobileCart: filteredItems.length > 0,
      },
      () => setCart(filteredItems)
    );

    if (filteredItems.length === 0) {
      document
        .querySelector("html")
        .setAttribute("style", "overflow: visible;");
    }
  };

  toggleAgreementVisibility = () => {
    if (this.state.showAgreement) {
      document
        .querySelector("html")
        .setAttribute("style", "overflow: visible;");
    } else {
      document.querySelector("html").setAttribute("style", "overflow: hidden;");
    }
    this.setState({ showAgreement: !this.state.showAgreement });
  };

  setVariantDropdownLabelText = (variants) => {
    if (variants[0][1].variantIsSize) {
      return translate(this.state.websiteLanguage, "PRODUCT_SIZE_LABEL");
    }
    return translate(this.state.websiteLanguage, "PRODUCT_TYPE_LABEL");
  };

  setVariantButtonText = (variants) => {
    if (variants[0][1].variantIsSize) {
      return translate(this.state.websiteLanguage, "FRONTPAGE_ADD_CHOOSE_SIZE");
    }
    return translate(this.state.websiteLanguage, "FRONTPAGE_ADD_CHOOSE_TYPE");
  };

  addToCartButtonText = (product) => {
    // Check if product has no items in stock at all
    if (product.itemsLeft === 0) {
      return translate(this.state.websiteLanguage, "FRONTPAGE_OUT_OF_STOCK");
    }
    let productHasVariants = false;
    // Check if product has variants
    if (product.variants && Object.keys(product.variants).length !== 0) {
      productHasVariants = true;
    }
    // Check if product has already been selected
    const productVariantSelectedIndex = this.state.variantsSelected.findIndex(
      (item) => item.productId === product._id
    );
    const productVariantAlreadySelected = productVariantSelectedIndex !== -1;
    if (productHasVariants && !productVariantAlreadySelected) {
      // Check to see if the product already has a selected variant
      return this.setVariantButtonText(Object.entries(product.variants));
    } else if (productHasVariants && productVariantAlreadySelected) {
      if (
        !this.state.variantsSelected[productVariantSelectedIndex].variantInStock
      ) {
        return translate(this.state.websiteLanguage, "FRONTPAGE_OUT_OF_STOCK");
      } else {
        return translate(this.state.websiteLanguage, "FRONTPAGE_ADD_TO_CART");
      }
    } else if (
      product._id === jegerbueId &&
      !this.state.qualificationDisclaimerAccepted
    ) {
      return translate(this.state.websiteLanguage, "FRONTPAGE_MORE_INFO");
    } else {
      return translate(this.state.websiteLanguage, "FRONTPAGE_ADD_TO_CART");
    }
  };

  setNotifyByEmail = (product, websiteLanguage) => {
    const itemName = product.name;
    const itemsLeft = product.itemsLeft;
    const productId = product._id;
    const variantsSelected = this.state.variantsSelected;

    // Check if product has already been selected
    const productVariantSelectedIndex = variantsSelected.findIndex(
      (item) => item.productId === productId
    );
    const productVariantAlreadySelected = productVariantSelectedIndex !== -1;
    let selectedVariantInStock = true;
    if (productVariantAlreadySelected) {
      selectedVariantInStock =
        variantsSelected[productVariantSelectedIndex].variantInStock;
    }

    if (itemsLeft === 0 || !selectedVariantInStock) {
      return (
        <div>
          <button
            className={`notify-in-stock${
              this.state.submittedItem === itemName ? "  disabled" : ""
            }`}
            onClick={() => this.notifyMeClick(itemName)}
          >
            <img
              className={`notification-icon${
                this.state.submittedItem === itemName ? " animation" : ""
              }`}
              alt="logo"
              src="./icons/notification.svg"
            />
            {this.state.submittedItem === itemName
              ? translate(websiteLanguage, "NOTIFY_ME_BUTTON_NOTIFIED")
              : translate(websiteLanguage, "NOTIFY_ME_BUTTON")}
          </button>
          {this.state.submittedItem === itemName && (
            <a
              className="notify-instagram-link"
              href="https://www.instagram.com/3charlie.no/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="instagram logo"
                src="./icons/instagram-icon.svg"
                className="notify-instagram-link-icon"
              />
              {translate(websiteLanguage, "NOTIFICATION_INSTAGRAM_LINK")}
            </a>
          )}
        </div>
      );
    }
    return null;
  };

  notifyMeClick = (itemName) => {
    this.setState(
      {
        showNotifyMeForm: false,
        notifyMeItem: itemName,
        submittedItem: "",
      },
      () => this.setState({ showNotifyMeForm: true })
    );
  };

  renderNotifyMeForm = (product) => {
    let itemName = product.name;

    let productVariantSelectedNotInStock = false;
    let itemVariantName = "";
    // Check to see if there are variants
    if (product.variants && Object.keys(product.variants).length !== 0) {
      const variantsSelected = this.state.variantsSelected;

      // Check if product has already been selected
      const productVariantSelectedIndex = variantsSelected.findIndex(
        (item) => item.productId === product._id
      );
      const productVariantAlreadySelected = productVariantSelectedIndex !== -1;
      let selectedVariantInStock = true;
      if (productVariantAlreadySelected) {
        selectedVariantInStock =
          variantsSelected[productVariantSelectedIndex].variantInStock;
        if (!selectedVariantInStock) {
          productVariantSelectedNotInStock = true;
          itemVariantName =
            variantsSelected[productVariantSelectedIndex].productVariantName;
        }
      }
    }

    if (this.state.showNotifyMeForm && this.state.notifyMeItem === itemName) {
      return (
        <div className="notify-me-form">
          <button
            className="close-notify-form-button"
            onClick={() => this.handleCloseNotifyMeForm()}
          >
            <img alt="close" src="./icons/close-icon.svg" />
          </button>
          <div className="notify-me-form-header">
            {translate(this.state.websiteLanguage, "NOTIFYME_FORM_HEADER_1")}
            {productVariantSelectedNotInStock ? itemVariantName : itemName}
            {translate(this.state.websiteLanguage, "NOTIFYME_FORM_HEADER_2")}
          </div>
          <input
            id="notifyMeEmailAddress"
            type="text"
            name="notifyMeEmailAddress"
            placeholder={translate(
              this.state.websiteLanguage,
              "CHECKOUT_FORM_EMAIL_PLACEHOLDER"
            )}
            onFocus={this.handleFocus}
            onChange={this.handleChange.bind(this)}
            value={this.state.notifyMeEmailAddress}
          />
          {this.state.emailValidationError && (
            <div className="notify-me-error">
              {this.state.emailValidationError}
            </div>
          )}
          <button
            className="notify-me-send-button"
            disabled={this.state.sendingNotifyMeEmail}
            onClick={() => this.handleNotifyMe(itemName, itemVariantName)}
          >
            {this.state.sendingNotifyMeEmail ? (
              <StageSpinner
                size={21}
                loading={this.state.sendingNotifyMeEmail}
                color="#000000"
              />
            ) : (
              translate(this.state.websiteLanguage, "NOTIFYME_SEND_BUTTON")
            )}
          </button>
        </div>
      );
    }
    return null;
  };

  handleCloseNotifyMeForm = () => {
    this.setState({
      showNotifyMeForm: false,
      notifyMeEmailAddress: "",
      notifyMeItem: undefined,
      emailValidationError: undefined,
      sendingNotifyMeEmail: false,
      notifyMeSubmitted: false,
    });
  };

  handleNotifyMe = async (itemName, itemVariantName) => {
    if (!validEmailAddress(this.state.notifyMeEmailAddress)) {
      document
        .querySelector("#notifyMeEmailAddress")
        .classList.add("validation-error");
      this.setState({
        emailValidationError: translate(
          this.state.websiteLanguage,
          "CHECKOUT_FORM_VALIDATION_EMAIL"
        ),
      });
    } else {
      this.setState({ sendingNotifyMeEmail: true });
      try {
        await strapi.createEntry("notifications", {
          email: this.state.notifyMeEmailAddress,
          product: itemVariantName ? itemVariantName : itemName,
          time: `${new Date(Date.now())}`,
          language: this.state.websiteLanguage,
        });
      } catch (e) {
        this.setState({
          sendingNotifyMeEmail: false,
          emailValidationError: translate(
            this.state.websiteLanguage,
            "NOTIFYME_GENERIC_ERROR"
          ),
        });
      }
      this.setState(
        {
          sendingNotifyMeEmail: false,
          notifyMeSubmitted: true,
        },
        () => this.handleNotifyMeSubmitted(itemName)
      );
    }
  };

  handleNotifyMeSubmitted = (itemName) => {
    this.setState({ submittedItem: itemName });
    this.handleCloseNotifyMeForm();
  };

  handleChange = (e) => {
    e.persist();
    this.setState({ notifyMeEmailAddress: e.target.value });
  };

  handleFocus = (e) => {
    this.setState({ emailValidationError: undefined });
    if (e.target && e.target.classList.contains("validation-error")) {
      e.target.classList.remove("validation-error");
    }
  };

  onSegmentControlClick = (productSelected) => {
    this.setState({ productSpecSelected: productSelected });
  };

  setPreselectedSegment = () => {
    if (
      hasProductsForLanguage(this.state.bigPatches, this.state.websiteLanguage)
    ) {
      this.setState({ productSpecSelected: "big" });
    } else if (
      hasProductsForLanguage(
        this.state.smallPatches,
        this.state.websiteLanguage
      )
    ) {
      this.setState({ productSpecSelected: "small" });
    } else {
      this.setState({ productSpecSelected: "buff" });
    }
  };

  scrollToProductFromUrl = () => {
    if (window.location.href.includes("#")) {
      const scrollTo = window.location.href.split("#")[1];
      document.querySelector(`#${scrollTo}`).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  setCampaignToDisplay = (campaigns) => {
    const activeCampaigns = campaigns.filter((campaign) => campaign.enabled);
    return activeCampaigns[Math.floor(Math.random() * activeCampaigns.length)];
  };

  routeToPath = withRouter(({ history }, path) => {
    history.push(path);
  });

  renderLanguageBar = (language, hideLanguageBar) => {
    let switchText = "🇳🇴 Trykk her for å bytte til norsk";
    let switchToLang = "no";
    if (language === "sv") {
      switchText = "🇸🇪 Tryck här för att byta till svenska";
      switchToLang = "sv";
    } else if (language === "da") {
      switchText = "🇩🇰 Tryk her for at skifte til dansk";
      switchToLang = "da";
    }
    return (
      <div
        className={`campaign-bar language-bar${
          hideLanguageBar ? " hidden" : ""
        }`}
      >
        <button className="language-bar-button invisible"></button>
        <button
          onClick={() => {
            setLanguageBarCookie();
            this.switchLanguage(switchToLang);
            this.setState({ hideLanguageBar: true });
          }}
          className="language-bar-button confirm"
        >
          {switchText}
        </button>
        <button
          onClick={() => {
            setLanguageBarCookie();
            this.setState({ hideLanguageBar: true });
          }}
          className="language-bar-button dismiss"
        >
          <img
            alt="close"
            src="./icons/close-icon.svg"
            className="language-bar-close-icon"
          />
        </button>
      </div>
    );
  };

  renderCampaignBar = (campaignToDisplay, loadingProducts) => {
    if (campaignToDisplay) {
      let campaignText;
      if (
        this.state.websiteLanguage === "no" &&
        campaignToDisplay.titleNorwegian
      ) {
        campaignText = campaignToDisplay.titleNorwegian;
      } else if (
        this.state.websiteLanguage === "sv" &&
        campaignToDisplay.titleSwedish
      ) {
        campaignText = campaignToDisplay.titleSwedish;
      } else if (
        this.state.websiteLanguage === "da" &&
        campaignToDisplay.titleDanish
      ) {
        campaignText = campaignToDisplay.titleDanish;
      } else if (
        this.state.websiteLanguage === "en" &&
        campaignToDisplay.titleEnglish
      ) {
        campaignText = campaignToDisplay.titleEnglish;
      } else {
        return null;
      }
      return (
        <div
          className={`campaign-bar ${loadingProducts ? "hide" : "show"}`}
          onClick={() => {
            const shortcut = campaignToDisplay.shortcut;
            if (shortcut.includes("http")) {
              window.open(shortcut, "_blank");
            } else if (shortcut.includes("customPatches")) {
              window.open("/customPatches", "_self");
            } else if (shortcut.includes("scrollto")) {
              const el = document.querySelector(`#${shortcut}`);
              el.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
              });
              setTimeout(() => {
                el.classList.add("highlight");
              }, 800);
              setTimeout(() => {
                el.classList.remove("highlight");
              }, 7500);
            } else {
              document.querySelector(`#${shortcut}`).scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
              });
            }
          }}
        >
          {campaignText}
        </div>
      );
    }
    return null;
  };

  renderFreeShippingText = (cartItems) => {
    let text = translate(this.state.websiteLanguage, "SHIPPING_FREE_AMOUNT");
    if (this.state.customOrder) {
      text = translate(this.state.websiteLanguage, "SHIPPING_FREE");
    } else {
      if (calculateAmount(cartItems, false) >= FREE_SHIPPING_NORWAY) {
        text = translate(this.state.websiteLanguage, "SHIPPING_FREE");
      }
    }
    return <div className="cart-shipping">{text}</div>;
  };

  renderDescriptionLanguage = (product, websiteLanguage) => {
    if (websiteLanguage === "no") {
      return product.description;
    } else if (websiteLanguage === "sv") {
      if (product.swedishDescription) {
        return product.swedishDescription;
      }
      return product.englishDescription;
    } else if (websiteLanguage === "da") {
      if (product.danishDescription) {
        return product.danishDescription;
      }
      return product.englishDescription;
    } else {
      return product.englishDescription;
    }
  };

  productDescriptionIsAvailable = (product, websiteLanguage) => {
    if (websiteLanguage === "no" && product.description !== "hidden") {
      return true;
    } else if (
      websiteLanguage === "en" &&
      product.englishDescription !== "hidden"
    ) {
      return true;
    } else if (websiteLanguage === "sv" && product.swedishDescription) {
      return true;
    } else if (websiteLanguage === "da" && product.danishDescription) {
      return true;
    }
    return false;
  };

  setDisabledAddToCartButton = (product) => {
    let productHasVariants = false;
    // Check to see if there are variants
    if (product.variants && Object.keys(product.variants).length !== 0) {
      productHasVariants = true;
    }
    // Check to see if the product already has a selected variant
    const productVariantSelectedIndex = this.state.variantsSelected.findIndex(
      (item) => item.productId === product._id
    );
    const productVariantAlreadySelected = productVariantSelectedIndex !== -1;

    if (product.itemsLeft === 0) {
      // There are no items left of the product
      return true;
    } else if (productHasVariants && !productVariantAlreadySelected) {
      // The product has variants but none are selected
      return true;
    } else if (productHasVariants && productVariantAlreadySelected) {
      if (
        !this.state.variantsSelected[productVariantSelectedIndex].variantInStock
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  setSelectedSizeClassName = (size, product) => {
    const selectedSizes = this.state.sizesSelected;
    const productSizeName = `${product.name} ${this.mapToCartSize(size)}`;
    const productId = product._id;

    const productAlreadySelected = selectedSizes.findIndex(
      (item) => item.productId === productId
    );

    if (productAlreadySelected === -1) {
      return "selected";
    } else if (
      selectedSizes[productAlreadySelected].productSizeName === productSizeName
    ) {
      return "selected";
    } else {
      return "unselected";
    }
  };

  renderVariantDropdown = (product) => {
    if (
      !product.variants ||
      !Object.keys(product.variants) ||
      Object.keys(product.variants).length === 0
    ) {
      return;
    }

    // Convert to array
    let productVariants = Object.entries(product.variants);

    // Sort alphabetically if types only
    if (!productVariants[0][1].variantIsSize) {
      productVariants = productVariants.sort((a, b) => {
        if (a[0] < b[0]) {
          return -1;
        }
        if (a[0] > b[0]) {
          return 1;
        }
        return 0;
      });
    }

    const selectedVariant = this.getSelectedProductVariant(product);

    return (
      <span className="type-dropdown">
        <span className="size-label">
          {this.setVariantDropdownLabelText(Object.entries(product.variants))}
        </span>
        <select
          name="type"
          id="type-dropdown"
          value={selectedVariant ? selectedVariant : "default"}
          onChange={(e) =>
            this.setSelectedProductVariant(
              e.target.value,
              product,
              productVariants
            )
          }
        >
          <option value="default" disabled>
            {translate(this.state.websiteLanguage, "FRONTPAGE_ADD_CHOOSE")}
          </option>
          {productVariants.map((item, key) => {
            return (
              <option key={key} value={item[0]}>
                {item[1].dropdownName}
              </option>
            );
          })}
        </select>
        <div className="select-dropdown-arrow" />
      </span>
    );
  };

  setSelectedSizeModal = (size) => {
    this.setState({ sizeSelectedModal: size });
  };

  closeModal = () => {
    this.setState({ modalOpen: false, sizeSelectedModal: undefined }, () => {
      document
        .querySelector("html")
        .setAttribute("style", "overflow: visible;");
    });
  };

  sizeGuideButtonClick = (e, product) => {
    e.stopPropagation();
    window.history.replaceState(null, null, " ");
    this.setState(
      { modalOpen: true, selectedProductInSizeModal: product },
      () => {
        document
          .querySelector("html")
          .setAttribute("style", "overflow: hidden;");
      }
    );
    if (this.state.variantsSelected.length > 0) {
      const productAlreadySelectedIndex = this.state.variantsSelected.findIndex(
        (item) => item.productId === product._id
      );
      if (productAlreadySelectedIndex !== -1) {
        this.setState({
          sizeSelectedModal:
            this.state.variantsSelected[productAlreadySelectedIndex].variant,
        });
      }
    }
  };

  modalClickOutside = (e) => {
    const overlay = document.querySelector(".modal");
    if (e.target === overlay) {
      this.closeModal();
    }
  };

  renderGalleryModal = (product) => {
    const { imageModalIndex, galleryImages } = this.state;
    if (product.name === this.state.imageModalItem) {
      return (
        <Lightbox
          mainSrc={galleryImages[imageModalIndex]}
          nextSrc={galleryImages[(imageModalIndex + 1) % galleryImages.length]}
          prevSrc={
            galleryImages[
              (imageModalIndex + galleryImages.length - 1) %
                galleryImages.length
            ]
          }
          onCloseRequest={() =>
            this.setState({
              imageModalOpen: false,
              galleryImages: [],
              imageModalIndex: 0,
            })
          }
          onMovePrevRequest={() =>
            this.setState({
              imageModalIndex:
                (imageModalIndex + galleryImages.length - 1) %
                galleryImages.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              imageModalIndex: (imageModalIndex + 1) % galleryImages.length,
            })
          }
          enableZoom={false}
          imageCaption={
            <a
              className="notify-instagram-link"
              href="https://www.instagram.com/3charlie.no"
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate(
                this.state.websiteLanguage,
                "GALLERY_MORE_IMAGES_LINK"
              )}
            </a>
          }
        />
      );
    }
  };

  setFeaturedProduct = (featuredProducts) => {
    const activefeaturedProducts = featuredProducts.filter(
      (product) => product.enabled
    );
    return activefeaturedProducts[
      Math.floor(Math.random() * activefeaturedProducts.length)
    ];
  };

  productImageClick = (product) => {
    const imageGallery = product.imageGallery;
    if (imageGallery.length) {
      const imageArray = Object.keys(imageGallery).map(
        (key) => imageGallery[key].url
      );
      this.setState(
        {
          galleryImages: [],
          imageModalIndex: 0,
          imageModalOpen: false,
          imageModalItem: product.name,
        },
        () => this.setState({ galleryImages: imageArray, imageModalOpen: true })
      );
    }
  };

  renderMoreImagesIcon = (product) => {
    if (product.imageGallery.length) {
      return (
        <img
          className="product-more-images-icon"
          alt="close"
          src="./icons/more-images-icon.svg"
        />
      );
    }
  };

  searchFieldOnChange = (event) => {
    this.setState({ searchString: event.target.value });
  };

  toggleSearchField = () => {
    this.setState(
      { showSearchField: !this.state.showSearchField, searchString: "" },
      () => {
        if (this.state.showSearchField) {
          document.querySelector("#products-search-field").focus();
          if (window.scrollY < 200) {
            document.querySelector(".menu-links-container").scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }
        }
      }
    );
  };

  filteredProducts = (
    {
      searchString,
      bigPatches,
      smallPatches,
      gadgets,
      tShirts,
      rankPatches,
      adhesives,
      watches,
    },
    productType
  ) => {
    if (productType === "bigPatches") {
      return bigPatches.filter((product) => {
        return (
          product.name.toLowerCase().includes(searchString.toLowerCase()) ||
          product.description.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    } else if (productType === "smallPatches") {
      return smallPatches.filter((product) => {
        return (
          product.name.toLowerCase().includes(searchString.toLowerCase()) ||
          product.description.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    } else if (productType === "gadgets") {
      return gadgets.filter((product) => {
        return (
          product.name.toLowerCase().includes(searchString.toLowerCase()) ||
          product.description.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    } else if (productType === "tShirts") {
      return tShirts.filter((product) => {
        return (
          product.name.toLowerCase().includes(searchString.toLowerCase()) ||
          product.description.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    } else if (productType === "rankPatches") {
      return rankPatches.filter((product) => {
        return (
          product.name.toLowerCase().includes(searchString.toLowerCase()) ||
          product.description.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    } else if (productType === "adhesives") {
      return adhesives.filter((product) => {
        return (
          product.name.toLowerCase().includes(searchString.toLowerCase()) ||
          product.description.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    } else if (productType === "watches") {
      return watches.filter((product) => {
        return (
          product.name.toLowerCase().includes(searchString.toLowerCase()) ||
          product.description.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    }
  };

  setSelectedVariantImage = (product) => {
    const selectedVariants = this.state.variantsSelected;
    const selectedVariantIndex = selectedVariants.findIndex(
      (item) => item.productId === product._id
    );
    if (
      selectedVariantIndex !== -1 &&
      selectedVariants[selectedVariantIndex].variantImageUrl
    ) {
      return selectedVariants[selectedVariantIndex].variantImageUrl;
    }
    if (product.image.length) {
      return product.image[0].url;
    }
    return product.image.url;
  };

  showLanguageBar = (language, location) => {
    const locationInNordics =
      location === "NO" || location === "SE" || location === "DK";
    const englishIsUsed = language === "en";
    return englishIsUsed && locationInNordics;
  };

  getClientIp = async () => {
    const rootUrl = "https://ipgeolocation.abstractapi.com/v1/";
    const apiKey = "854b85f23bc34e58a1715a0425a5dab8";
    const fields = "country_code";
    const fullUrl = `${rootUrl}?api_key=${apiKey}&fields=${fields}`;

    const response = await axios.get(fullUrl).then((response) => {
      return response.data.country_code;
    });
    return response;
  };

  checkboxOnChange = () => {
    this.setState({
      qualificationDisclaimerAccepted:
        !this.state.qualificationDisclaimerAccepted,
    });
  };

  renderFeaturedProduct = (product) => {
    if (product) {
      let title = product.title;
      let description = product.description;
      if (this.state.websiteLanguage === "sv") {
        title = product.swedishTitle;
        description = product.swedishDescription;
      } else if (this.state.websiteLanguage === "dk") {
        title = product.danishTitle;
        description = product.danishDescription;
      } else if (this.state.websiteLanguage === "en") {
        title = product.englishTitle;
        description = product.englishDescription;
      }
      return (
        <div className="featured-product">
          <div
            className="image-main-container"
            style={{ backgroundImage: `url(${product.imageUrlList.image1})` }}
          ></div>
          <div className="image-extra-container">
            <div
              className="image-extra"
              style={{ backgroundImage: `url(${product.imageUrlList.image2})` }}
            >
              <GlitchText iterationCount={"6"}>{title}</GlitchText>
              <p className="featured-product-description">{description}</p>
              <NavLink
                className="featured-more-link"
                to={`./${product.readmoreLink}`}
              >
                {translate(this.state.websiteLanguage, "FRONTPAGE_MORE_INFO")}
              </NavLink>
            </div>
            <div
              className="image-extra"
              style={{ backgroundImage: `url(${product.imageUrlList.image3})` }}
            ></div>
          </div>
        </div>
      );
    }
  };

  render() {
    const {
      bigPatches,
      smallPatches,
      gadgets,
      tShirts,
      rankPatches,
      adhesives,
      watches,
      loadingProducts,
      loadingBigPatches,
      loadingSmallPatches,
      loadingGadgets,
      loadingTshirts,
      loadingRankPatches,
      loadingAdhesives,
      loadingWatches,
      cartItems,
      websiteLanguage,
      showTestProducts,
      modalOpen,
      imageModalOpen,
      searchString,
      heroProduct,
      campaignToDisplay,
      hideLanguageBar,
      clientLocation,
      loadingScrollToProduct,
    } = this.state;
    return (
      <ClearCache>
        {({ isLatestVersion, emptyCacheStorage }) => {
          if (!isLatestVersion) {
            emptyCacheStorage();
            console.log("new content available, refreshing");
          }
          return (
            <div
              className={`root-container main ${
                cartItems.length ? "cart-visible" : "cart-hidden"
              }`}
            >
              {modalOpen && (
                <Modal
                  close={this.closeModal}
                  product={this.state.selectedProductInSizeModal}
                  websiteLanguage={this.state.websiteLanguage}
                  sizeSelectedModal={this.state.sizeSelectedModal}
                  setSelectedSizeModal={this.setSelectedSizeModal}
                  setSelectedSizeClassName={this.setSelectedSizeClassName}
                  modalClickOutside={this.modalClickOutside}
                  checkboxChecked={this.state.qualificationDisclaimerAccepted}
                  checkboxOnChange={this.checkboxOnChange}
                  productVariantSelected={this.getSelectedProductVariant(
                    this.state.selectedProductInSizeModal
                  )}
                  setSelectedProductVariant={this.setSelectedProductVariant}
                />
              )}
              <Navbar
                showBackButton={false}
                websiteLanguage={websiteLanguage}
                transparent={false}
              />
              <main>
                <section className="hero">
                  <div className="hero-bg-text">
                    {this.state.loadingScrollToProduct ? (
                      <div className="spinner-bg">
                        <StageSpinner
                          loading={loadingScrollToProduct}
                          color="#bcf135"
                        />
                      </div>
                    ) : (
                      <GlitchText iterationCount={"10"}>3CHARLIE</GlitchText>
                    )}
                  </div>
                  {/* 3c logo <img className="hero-site-logo" alt="logo" src="./icons/3charlie.svg"></img> */}
                  {setHeroContent(
                    websiteLanguage,
                    heroProduct,
                    loadingScrollToProduct
                  )}
                </section>
                {/* Language Bar & Campaign Bar */}
                {!hideLanguageBar &&
                this.showLanguageBar(
                  websiteLanguage,
                  clientLocation,
                  hideLanguageBar
                )
                  ? this.renderLanguageBar(websiteLanguage, hideLanguageBar)
                  : this.renderCampaignBar(campaignToDisplay, loadingAdhesives)}
                <HeroLinks
                  websiteLanguage={websiteLanguage}
                  patches={bigPatches}
                  smallPatches={smallPatches}
                  gadgets={gadgets}
                  tShirts={tShirts}
                  searchFieldOnChange={this.searchFieldOnChange}
                  showSearchField={this.state.showSearchField}
                  toggleSearchField={this.toggleSearchField}
                  patchesLoading={this.state.loadingBigPatches}
                  buffsLoading={this.state.loadingGadgets}
                  apparelLoading={this.state.loadingTshirts}
                />

                <section className="section-patches">
                  <div
                    className={`section-container${
                      cartItems.length ? " cart-visible" : ""
                    }`}
                  >
                    {/* Products */}
                    <div className="product-items">
                      {/* Dummy Product */}
                      {showTestProducts && (
                        <div className="center-item">
                          <button
                            onClick={() =>
                              this.handleAddToCart(dummyProduct, false)
                            }
                            className="product-buy-button"
                          >
                            Add dummy product
                          </button>
                        </div>
                      )}

                      {/* Empty Search Header */}
                      {!loadingProducts &&
                        searchString !== "" &&
                        this.filteredProducts(this.state, "bigPatches")
                          .length === 0 &&
                        this.filteredProducts(this.state, "smallPatches")
                          .length === 0 &&
                        this.filteredProducts(this.state, "gadgets").length ===
                          0 &&
                        this.filteredProducts(this.state, "tShirts").length ===
                          0 &&
                        this.filteredProducts(this.state, "rankPatches")
                          .length === 0 && (
                          <h2 className="no-search-matches">
                            {translate(
                              websiteLanguage,
                              "FRONTPAGE_SEARCH_NO_MATCHES"
                            )}
                          </h2>
                        )}

                      {/* Rank Patches */}
                      {showRankPatches &&
                        hasProductsForLanguage(rankPatches, websiteLanguage) &&
                        this.filteredProducts(this.state, "rankPatches")
                          .length > 0 && (
                          <h2 id="rankpatches">
                            {translate(
                              websiteLanguage,
                              "FRONTPAGE_RANKPATCHES_HEADER"
                            )}
                          </h2>
                        )}
                      {showRankPatches && loadingRankPatches && (
                        <div className="loading-spacer" />
                      )}
                      {showRankPatches && (
                        <div className="spinner-container">
                          <StageSpinner
                            loading={loadingRankPatches}
                            color="#bcf135"
                          />
                        </div>
                      )}
                      {showRankPatches && loadingRankPatches && (
                        <div className="loading-spacer" />
                      )}
                      {showRankPatches && (
                        <div className="product-items-inner">
                          {this.filteredProducts(this.state, "rankPatches")
                            .sort((product) => (product.featured ? -1 : 1))
                            .map((product) =>
                              this.productDescriptionIsAvailable(
                                product,
                                websiteLanguage
                              ) && product.enabled ? (
                                <div
                                  className="product-item"
                                  key={product._id}
                                  id={product.name.replace(/\s/g, "")}
                                >
                                  {setCampaignLabel(
                                    product.label,
                                    websiteLanguage
                                  )}
                                  {this.setNotifyByEmail(
                                    product,
                                    websiteLanguage
                                  )}
                                  {this.renderNotifyMeForm(product)}
                                  {imageModalOpen &&
                                    product.imageGallery.length > 0 &&
                                    this.renderGalleryModal(product)}
                                  <Link
                                    className="product-image"
                                    to={`../${spaceToDash(product.name)}`}
                                  >
                                    <img
                                      src={this.setSelectedVariantImage(
                                        product
                                      )}
                                      alt={`Bilde av ${product.name}`}
                                    />
                                  </Link>
                                  <div className="product-text-container">
                                    <div className="product-title">
                                      <Link
                                        to={`/${spaceToDash(product.name)}`}
                                      >
                                        {product.name}
                                      </Link>
                                    </div>
                                    <div className="product-description">
                                      {this.renderDescriptionLanguage(
                                        product,
                                        websiteLanguage
                                      )}
                                    </div>
                                    <div className="product-price">
                                      {product.price}{" "}
                                      {translate(
                                        this.state.websiteLanguage,
                                        "CURRENCY_NORWEGIAN"
                                      )}
                                      {this.renderVariantDropdown(product)}
                                    </div>
                                    <div className="product-cart-button">
                                      <button
                                        onClick={() =>
                                          this.handleAddToCart(product, false)
                                        }
                                        disabled={this.setDisabledAddToCartButton(
                                          product
                                        )}
                                        className="product-buy-button"
                                      >
                                        {this.addToCartButtonText(product)}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : null
                            )}
                        </div>
                      )}

                      {/* Custom patches banner */}
                      {searchString === "" && (
                        <NavLink
                          to="./customPatches"
                          className="section-custom-patches"
                          id="custom"
                        >
                          <section>
                            <div className="section-container">
                              {translate(
                                this.state.websiteLanguage,
                                "FRONTPAGE_CUSTOM_PATCHES_HEADER"
                              )}
                            </div>
                            <div className="section-container">
                              {translate(
                                this.state.websiteLanguage,
                                "FRONTPAGE_CUSTOM_PATCHES_BUTTON"
                              )}
                            </div>
                          </section>
                        </NavLink>
                      )}

                      {/* T-shirts */}
                      {hasProductsForLanguage(tShirts, websiteLanguage) &&
                        this.filteredProducts(this.state, "tShirts").length >
                          0 && (
                          <h2 id="tshirts">
                            {translate(
                              websiteLanguage,
                              "FRONTPAGE_TSHIRTS_HEADER"
                            )}
                          </h2>
                        )}
                      {loadingTshirts && <div className="loading-spacer" />}
                      <div className="spinner-container">
                        <StageSpinner
                          loading={loadingTshirts}
                          color="#bcf135"
                        />
                      </div>
                      {loadingTshirts && <div className="loading-spacer" />}
                      <div className="product-items-inner">
                        {this.filteredProducts(this.state, "tShirts")
                          .sort((product) => (product.featured ? -1 : 1))
                          .map((product) =>
                            this.productDescriptionIsAvailable(
                              product,
                              websiteLanguage
                            ) && product.enabled ? (
                              <div
                                className="product-item"
                                key={product._id}
                                id={product.name.replace(/\s/g, "")}
                              >
                                {setCampaignLabel(
                                  product.label,
                                  websiteLanguage
                                )}
                                {this.setNotifyByEmail(
                                  product,
                                  websiteLanguage
                                )}
                                {this.renderNotifyMeForm(product)}
                                {imageModalOpen &&
                                  product.imageGallery.length > 0 &&
                                  this.renderGalleryModal(product)}
                                <Link
                                  className="product-image"
                                  to={`../${spaceToDash(product.name)}`}
                                >
                                  <img
                                    src={this.setSelectedVariantImage(product)}
                                    alt={`Bilde av ${product.name}`}
                                  />
                                  {showSizeGuideButton(product) && (
                                    <button
                                      className="size-guide-button"
                                      onClick={(e) =>
                                        this.sizeGuideButtonClick(e, product)
                                      }
                                    >
                                      <div className="size-guide-button-text">
                                        {" "}
                                        {translate(
                                          websiteLanguage,
                                          "PRODUCT_SIZE_GUIDE"
                                        )}
                                      </div>
                                      <img
                                        alt="close"
                                        src="./icons/measure-tape-icon.svg"
                                      />
                                    </button>
                                  )}
                                </Link>
                                <div className="product-text-container">
                                  <div className="product-title">
                                    <Link to={`/${spaceToDash(product.name)}`}>
                                      {product.name}
                                    </Link>
                                  </div>
                                  <div className="product-description">
                                    {this.renderDescriptionLanguage(
                                      product,
                                      websiteLanguage
                                    )}
                                  </div>
                                  <div className="product-price">
                                    {product.price}{" "}
                                    {translate(
                                      this.state.websiteLanguage,
                                      "CURRENCY_NORWEGIAN"
                                    )}
                                    {this.renderVariantDropdown(product)}
                                  </div>
                                  <div className="product-cart-button">
                                    <button
                                      onClick={() =>
                                        this.handleAddToCart(product, false)
                                      }
                                      disabled={this.setDisabledAddToCartButton(
                                        product
                                      )}
                                      className="product-buy-button"
                                    >
                                      {this.addToCartButtonText(product)}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : null
                          )}
                      </div>

                      {/* Watches banner */}
                      {searchString === "" && (
                        <NavLink
                          to="./category/watches"
                          className="section-custom-patches watches"
                          id="custom"
                        >
                          <section>
                            <div className="section-container">
                              {translate(
                                this.state.websiteLanguage,
                                "FRONTPAGE_WATCHES_BANNER_HEADER"
                              )}
                            </div>
                            <div className="section-container">
                              {translate(
                                this.state.websiteLanguage,
                                "FRONTPAGE_WATCHES_BANNER_BUTTON"
                              )}
                            </div>
                          </section>
                        </NavLink>
                      )}

                      {/* Big Flag */}
                      {hasProductsForLanguage(bigPatches, websiteLanguage) &&
                        this.filteredProducts(this.state, "bigPatches").length >
                          0 && (
                          <h2 id="patches">
                            {translate(
                              websiteLanguage,
                              "FRONTPAGE_BIG_PATCHES_HEADER"
                            )}
                          </h2>
                        )}
                      {loadingBigPatches && <div className="loading-spacer" />}
                      <div className="spinner-container">
                        <StageSpinner
                          loading={loadingBigPatches}
                          color="#bcf135"
                        />
                      </div>
                      {loadingBigPatches && <div className="loading-spacer" />}
                      <div className="product-items-inner">
                        {this.filteredProducts(this.state, "bigPatches")
                          .sort((product) => (product.featured ? -1 : 1))
                          .map((product) =>
                            this.productDescriptionIsAvailable(
                              product,
                              websiteLanguage
                            ) && product.enabled ? (
                              <div
                                className="product-item"
                                key={product._id}
                                id={product.name.replace(/\s/g, "")}
                              >
                                {setCampaignLabel(
                                  product.label,
                                  websiteLanguage
                                )}
                                {this.setNotifyByEmail(
                                  product,
                                  websiteLanguage
                                )}
                                {this.renderNotifyMeForm(product)}
                                {imageModalOpen &&
                                  product.imageGallery.length > 0 &&
                                  this.renderGalleryModal(product)}
                                <Link
                                  className="product-image"
                                  to={`../${spaceToDash(product.name)}`}
                                >
                                  <img
                                    src={this.setSelectedVariantImage(product)}
                                    alt={`Bilde av ${product.name}`}
                                  />
                                </Link>
                                <div className="product-text-container">
                                  <div className="product-title">
                                    <Link to={`/${spaceToDash(product.name)}`}>
                                      {product.name}
                                    </Link>
                                  </div>
                                  <div className="product-description">
                                    {this.renderDescriptionLanguage(
                                      product,
                                      websiteLanguage
                                    )}
                                  </div>
                                  <div className="product-price">
                                    {product.price}{" "}
                                    {translate(
                                      this.state.websiteLanguage,
                                      "CURRENCY_NORWEGIAN"
                                    )}
                                    {this.renderVariantDropdown(product)}
                                  </div>
                                  <div className="product-cart-button">
                                    <button
                                      onClick={() =>
                                        this.handleAddToCart(product, false)
                                      }
                                      disabled={this.setDisabledAddToCartButton(
                                        product
                                      )}
                                      className="product-buy-button"
                                    >
                                      {this.addToCartButtonText(product)}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : null
                          )}
                      </div>

                      {/* Small Flag */}
                      {hasProductsForLanguage(smallPatches, websiteLanguage) &&
                        this.filteredProducts(this.state, "smallPatches")
                          .length > 0 && (
                          <h2 id="smallpatches">
                            {translate(
                              websiteLanguage,
                              "FRONTPAGE_SMALL_PATCHES_HEADER"
                            )}
                          </h2>
                        )}
                      {loadingSmallPatches && (
                        <div className="loading-spacer" />
                      )}
                      <div className="spinner-container">
                        <StageSpinner
                          loading={loadingSmallPatches}
                          color="#bcf135"
                        />
                      </div>
                      {loadingSmallPatches && (
                        <div className="loading-spacer" />
                      )}
                      <div className="product-items-inner">
                        {this.filteredProducts(this.state, "smallPatches")
                          .sort((product) => (product.featured ? -1 : 1))
                          .map((product) =>
                            this.productDescriptionIsAvailable(
                              product,
                              websiteLanguage
                            ) && product.enabled ? (
                              <div
                                className="product-item"
                                key={product._id}
                                id={product.name.replace(/\s/g, "")}
                              >
                                {setCampaignLabel(
                                  product.label,
                                  websiteLanguage
                                )}
                                {this.setNotifyByEmail(
                                  product,
                                  websiteLanguage
                                )}
                                {this.renderNotifyMeForm(product)}
                                {imageModalOpen &&
                                  product.imageGallery.length > 0 &&
                                  this.renderGalleryModal(product)}
                                <Link
                                  className="product-image"
                                  to={`../${spaceToDash(product.name)}`}
                                >
                                  <img
                                    src={this.setSelectedVariantImage(product)}
                                    alt={`Bilde av ${product.name}`}
                                  />
                                </Link>
                                <div className="product-text-container">
                                  <div className="product-title">
                                    <Link to={`/${spaceToDash(product.name)}`}>
                                      {product.name}
                                    </Link>
                                  </div>
                                  <div className="product-description">
                                    {this.renderDescriptionLanguage(
                                      product,
                                      websiteLanguage
                                    )}
                                  </div>
                                  <div className="product-price">
                                    {product.price}{" "}
                                    {translate(
                                      this.state.websiteLanguage,
                                      "CURRENCY_NORWEGIAN"
                                    )}
                                    {this.renderVariantDropdown(product)}
                                  </div>
                                  <div className="product-cart-button">
                                    <button
                                      onClick={() =>
                                        this.handleAddToCart(product, false)
                                      }
                                      disabled={this.setDisabledAddToCartButton(
                                        product
                                      )}
                                      className="product-buy-button"
                                    >
                                      {this.addToCartButtonText(product)}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : null
                          )}
                      </div>

                      {/* Buff/div */}
                      {hasProductsForLanguage(gadgets, websiteLanguage) &&
                        this.filteredProducts(this.state, "gadgets").length >
                          0 && (
                          <h2 id="gadgets">
                            {translate(
                              websiteLanguage,
                              "FRONTPAGE_GADGETS_HEADER"
                            )}
                          </h2>
                        )}
                      {loadingGadgets && <div className="loading-spacer" />}
                      <div className="spinner-container">
                        <StageSpinner
                          loading={loadingGadgets}
                          color="#bcf135"
                        />
                      </div>
                      {loadingGadgets && <div className="loading-spacer" />}
                      <div className="product-items-inner">
                        {this.filteredProducts(this.state, "gadgets")
                          .sort((product) => (product.featured ? -1 : 1))
                          .map((product) =>
                            this.productDescriptionIsAvailable(
                              product,
                              websiteLanguage
                            ) && product.enabled ? (
                              <div
                                className="product-item"
                                key={product._id}
                                id={product.name.replace(/\s/g, "")}
                              >
                                {setCampaignLabel(
                                  product.label,
                                  websiteLanguage
                                )}
                                {this.setNotifyByEmail(
                                  product,
                                  websiteLanguage
                                )}
                                {this.renderNotifyMeForm(product)}
                                {imageModalOpen &&
                                  product.imageGallery.length > 0 &&
                                  this.renderGalleryModal(product)}
                                <Link
                                  className="product-image"
                                  to={`../${spaceToDash(product.name)}`}
                                >
                                  <img
                                    src={this.setSelectedVariantImage(product)}
                                    alt={`Bilde av ${product.name}`}
                                  />
                                </Link>
                                <div className="product-text-container">
                                  <div className="product-title">
                                    <Link to={`/${spaceToDash(product.name)}`}>
                                      {product.name}
                                    </Link>
                                  </div>
                                  <div className="product-description">
                                    {this.renderDescriptionLanguage(
                                      product,
                                      websiteLanguage
                                    )}
                                  </div>
                                  <div className="product-price">
                                    {product.price}{" "}
                                    {translate(
                                      this.state.websiteLanguage,
                                      "CURRENCY_NORWEGIAN"
                                    )}
                                    {this.renderVariantDropdown(product)}
                                  </div>
                                  <div className="product-cart-button">
                                    <button
                                      onClick={() =>
                                        this.handleAddToCart(product, false)
                                      }
                                      disabled={this.setDisabledAddToCartButton(
                                        product
                                      )}
                                      className="product-buy-button"
                                    >
                                      {this.addToCartButtonText(product)}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : null
                          )}
                      </div>

                      {/* Watches */}
                      {hasProductsForLanguage(watches, websiteLanguage) &&
                        this.filteredProducts(this.state, "watches").length >
                          0 && (
                          <h2 id="patches">
                            {translate(
                              websiteLanguage,
                              "FRONTPAGE_WATCHES_HEADER"
                            )}
                          </h2>
                        )}
                      {loadingWatches && <div className="loading-spacer" />}
                      <div className="spinner-container">
                        <StageSpinner
                          loading={loadingWatches}
                          color="#bcf135"
                        />
                      </div>
                      {loadingWatches && <div className="loading-spacer" />}
                      <div className="product-items-inner">
                        {this.filteredProducts(this.state, "watches")
                          .sort((product) => (product.featured ? -1 : 1))
                          .map((product) =>
                            this.productDescriptionIsAvailable(
                              product,
                              websiteLanguage
                            ) && product.enabled ? (
                              <div
                                className="product-item"
                                key={product._id}
                                id={product.name.replace(/\s/g, "")}
                              >
                                {setCampaignLabel(
                                  product.label,
                                  websiteLanguage
                                )}
                                {this.setNotifyByEmail(
                                  product,
                                  websiteLanguage
                                )}
                                {this.renderNotifyMeForm(product)}
                                {imageModalOpen &&
                                  product.imageGallery.length > 0 &&
                                  this.renderGalleryModal(product)}
                                <Link
                                  className="product-image"
                                  to={`../${spaceToDash(product.name)}`}
                                >
                                  <img
                                    src={this.setSelectedVariantImage(product)}
                                    alt={`Bilde av ${product.name}`}
                                  />
                                </Link>
                                <div className="product-text-container">
                                  <div className="product-title">
                                    <Link to={`/${spaceToDash(product.name)}`}>
                                      {product.name}
                                    </Link>
                                  </div>
                                  <div className="product-description">
                                    {this.renderDescriptionLanguage(
                                      product,
                                      websiteLanguage
                                    )}
                                  </div>
                                  <div className="product-price">
                                    {product.price}{" "}
                                    {translate(
                                      this.state.websiteLanguage,
                                      "CURRENCY_NORWEGIAN"
                                    )}
                                    {this.renderVariantDropdown(product)}
                                  </div>
                                  <div className="product-cart-button">
                                    <button
                                      onClick={() =>
                                        this.handleAddToCart(product, false)
                                      }
                                      disabled={this.setDisabledAddToCartButton(
                                        product
                                      )}
                                      className="product-buy-button"
                                    >
                                      {this.addToCartButtonText(product)}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : null
                          )}
                      </div>

                      {/* Adhesives */}
                      {hasProductsForLanguage(adhesives, websiteLanguage) &&
                        this.filteredProducts(this.state, "adhesives").length >
                          0 && (
                          <h2 id="adhesives">
                            {translate(
                              websiteLanguage,
                              "FRONTPAGE_ADHESIVES_HEADER"
                            )}
                          </h2>
                        )}
                      {loadingAdhesives && <div className="loading-spacer" />}
                      <div className="spinner-container">
                        <StageSpinner
                          loading={loadingAdhesives}
                          color="#bcf135"
                        />
                      </div>
                      {loadingAdhesives && <div className="loading-spacer" />}
                      {
                        <div className="product-items-inner">
                          {this.filteredProducts(this.state, "adhesives")
                            .sort((product) => (product.featured ? -1 : 1))
                            .map((product) =>
                              this.productDescriptionIsAvailable(
                                product,
                                websiteLanguage
                              ) && product.enabled ? (
                                <div
                                  className="product-item"
                                  key={product._id}
                                  id={product.name.replace(/\s/g, "")}
                                >
                                  {setCampaignLabel(
                                    product.label,
                                    websiteLanguage
                                  )}
                                  {this.setNotifyByEmail(
                                    product,
                                    websiteLanguage
                                  )}
                                  {this.renderNotifyMeForm(product)}
                                  {imageModalOpen &&
                                    product.imageGallery.length > 0 &&
                                    this.renderGalleryModal(product)}
                                  <Link
                                    className="product-image"
                                    to={`../${spaceToDash(product.name)}`}
                                  >
                                    <img
                                      src={this.setSelectedVariantImage(
                                        product
                                      )}
                                      alt={`Bilde av ${product.name}`}
                                    />
                                  </Link>
                                  <div className="product-text-container">
                                    <div className="product-title">
                                      <Link
                                        to={`/${spaceToDash(product.name)}`}
                                      >
                                        {product.name}
                                      </Link>
                                    </div>
                                    <div className="product-description">
                                      {this.renderDescriptionLanguage(
                                        product,
                                        websiteLanguage
                                      )}
                                    </div>
                                    <div className="product-price">
                                      {product.price}{" "}
                                      {translate(
                                        this.state.websiteLanguage,
                                        "CURRENCY_NORWEGIAN"
                                      )}
                                      {this.renderVariantDropdown(product)}
                                    </div>
                                    <div className="product-cart-button">
                                      <button
                                        onClick={() =>
                                          this.handleAddToCart(product, false)
                                        }
                                        disabled={this.setDisabledAddToCartButton(
                                          product
                                        )}
                                        className="product-buy-button"
                                      >
                                        {this.addToCartButtonText(product)}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ) : null
                            )}
                        </div>
                      }

                      {/* Cart */}
                      <div
                        className={`cart ${
                          cartItems.length ? "visible" : "hidden"
                        }`}
                      >
                        <div className="cart-inner">
                          {/* Cart items */}
                          <div
                            className={`cart-items-container ${
                              this.state.showMobileCart ? "visible" : "hidden"
                            }`}
                          >
                            <div
                              className="cart-header"
                              onClick={() => {
                                this.setState({ showMobileCart: false }, () =>
                                  document
                                    .querySelector("html")
                                    .setAttribute("style", "overflow: visible;")
                                );
                              }}
                            ></div>
                            <div className="cart-content">
                              <h3
                                className={`cart-heading ${
                                  this.state.showMobileCart
                                    ? "visible"
                                    : "hidden"
                                }`}
                              >
                                {translate(websiteLanguage, "CART_TITLE")}
                                <button
                                  className="close-modal-button"
                                  onClick={() => {
                                    this.setState(
                                      { showMobileCart: false },
                                      () =>
                                        document
                                          .querySelector("html")
                                          .setAttribute(
                                            "style",
                                            "overflow: visible;"
                                          )
                                    );
                                  }}
                                >
                                  <img
                                    alt="close"
                                    src="./icons/close-icon.svg"
                                  />
                                </button>
                              </h3>
                              <div className="cart-row-items">
                                {cartItems.map((item) => (
                                  <div
                                    className="cart-item-row"
                                    key={item._id + item.name}
                                  >
                                    <div className="cart-items">
                                      {item._id !== "customOrder" &&
                                        item.quantity}
                                      {item._id !== "customOrder" ? " " : ""}
                                      {item.name}: {item.quantity * item.price}{" "}
                                      {translate(
                                        this.state.websiteLanguage,
                                        "CURRENCY_NORWEGIAN"
                                      )}
                                    </div>
                                    <div className="cart-buttons">
                                      {item._id !== "customOrder" && (
                                        <button
                                          className="add-cart-item"
                                          onClick={() =>
                                            this.handleAddToCart(item, true)
                                          }
                                        >
                                          <img
                                            src="./icons/add.svg"
                                            alt="Add item icon"
                                          />
                                        </button>
                                      )}
                                      {item._id !== "customOrder" && (
                                        <button
                                          className="subtract-cart-item"
                                          onClick={() =>
                                            this.removeItemFromCart(item, false)
                                          }
                                        >
                                          <img
                                            src="./icons/subtract.svg"
                                            alt="Subtract item icon"
                                          />
                                        </button>
                                      )}
                                      <button
                                        className="remove-cart-item"
                                        onClick={() =>
                                          this.deleteItemFromCart(item)
                                        }
                                      >
                                        <img
                                          src="./icons/remove.svg"
                                          alt="Remove item icon"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {(websiteLanguage === "no" ||
                                websiteLanguage === "en") &&
                                this.renderFreeShippingText(cartItems)}
                            </div>
                          </div>

                          <div className="cart-summary">
                            <button
                              className="mobile-cart-toggle"
                              onClick={() => {
                                this.setState(
                                  {
                                    showMobileCart: !this.state.showMobileCart,
                                  },
                                  () => {
                                    if (this.state.showMobileCart) {
                                      document
                                        .querySelector("html")
                                        .setAttribute(
                                          "style",
                                          "overflow: hidden;"
                                        );
                                    } else {
                                      document
                                        .querySelector("html")
                                        .setAttribute(
                                          "style",
                                          "overflow: visible;"
                                        );
                                    }
                                  }
                                );
                              }}
                            >
                              <img
                                className={`chevron-icon ${
                                  this.state.showMobileCart ? "down" : "up"
                                }`}
                                src="./icons/chevron.svg"
                                alt="chevron-icon"
                              ></img>
                              <img
                                className="cart-icon"
                                src="./icons/ruck.svg"
                                alt="cart-icon"
                              />
                              <div
                                className={`cart-badge ${
                                  this.state.itemAddedToCart ? "animation" : ""
                                }`}
                              >
                                {itemsInCart(this.state.cartItems)}
                              </div>
                            </button>
                            <div
                              className="cart-total-price"
                              onClick={() => {
                                this.setState(
                                  {
                                    showMobileCart: !this.state.showMobileCart,
                                  },
                                  () => {
                                    if (this.state.showMobileCart) {
                                      document
                                        .querySelector("html")
                                        .setAttribute(
                                          "style",
                                          "overflow: hidden;"
                                        );
                                    } else {
                                      document
                                        .querySelector("html")
                                        .setAttribute(
                                          "style",
                                          "overflow: visible;"
                                        );
                                    }
                                  }
                                );
                              }}
                            >
                              {translate(
                                this.state.websiteLanguage,
                                "CART_SUBTOTAL_AMOUNT"
                              )}{" "}
                              {calculateAmount(cartItems, false)}
                              {",00 "}
                              {translate(
                                this.state.websiteLanguage,
                                "CURRENCY_NORWEGIAN"
                              )}
                            </div>
                            <div className="cart-to-checkout">
                              {cartItems.length > 0 && (
                                <button
                                  className="checkout-button"
                                  onClick={() =>
                                    this.props.history.push("/checkout")
                                  }
                                >
                                  {translate(
                                    this.state.websiteLanguage,
                                    "CART_TO_CHECKOUT"
                                  )}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section-featured-product">
                  {this.renderFeaturedProduct(
                    this.state.featuredProductToDisplay
                  )}
                </section>

                {websiteLanguage === "no" && (
                  <section className="section-opra-discount">
                    <div className="content">
                      <span>
                        10 % rabatt via{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.opra.no"
                        >
                          OPRA.no
                        </a>
                      </span>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.opra.no"
                      >
                        <img
                          className="opra-logo"
                          alt="OPRA logo"
                          src="./icons/opra-logo.svg"
                        />
                      </a>
                    </div>
                  </section>
                )}

                <div className="image-scroller">
                  <div className="insta-feed-title">
                    <a
                      className="instagram-link"
                      href="https://www.instagram.com/3charlie.no/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt="instagram logo"
                        src="./icons/instagram-icon.svg"
                      />
                      {translate(websiteLanguage, "INSTAGRAM_LINK")}
                    </a>
                  </div>
                  <InstagramFeed token={instagramToken} counter="6" />
                </div>

                <section className="section-patch-pricing">
                  <div className="section-patch-pricing-container">
                    <div className="pricing-item">
                      <img
                        className="tested"
                        alt="shipping"
                        src="./icons/weather.svg"
                      />
                      <div>
                        {translate(websiteLanguage, "FRONTPAGE_FIELD_TESTED")}
                      </div>
                    </div>
                    <div className="pricing-item">
                      <img className="nvg" alt="nvg" src="./icons/nvg.svg" />
                      <div>
                        {translate(websiteLanguage, "FRONTPAGE_IR_REFLEXIVE")}
                      </div>
                    </div>
                    <div className="pricing-item">
                      <img
                        className="shipping"
                        alt="shipping"
                        src="./icons/shipping.svg"
                      />
                      <div>
                        {translate(websiteLanguage, "FRONTPAGE_SHIPPING")}
                      </div>
                    </div>
                    <div className="pricing-item">
                      <img
                        className="mailbox"
                        alt="shipping"
                        src="./icons/mailbox.svg"
                      />
                      <div>
                        {translate(websiteLanguage, "FRONTPAGE_MAILBOX_DIRECT")}
                      </div>
                    </div>
                  </div>
                  {(websiteLanguage === "no" || websiteLanguage === "en") && (
                    <div className="disclaimer">
                      {translate(
                        websiteLanguage,
                        "FRONTPAGE_SHIPPING_DISCLAIMER"
                      )}
                    </div>
                  )}
                </section>
                <Footer
                  showAgreement={this.state.showAgreement}
                  toggleAgreementVisibility={this.toggleAgreementVisibility}
                  language={this.state.websiteLanguage}
                  switchLanguage={this.switchLanguage}
                />
              </main>
              <CookieConsent
                location="bottom"
                buttonText={translate(
                  this.state.websiteLanguage,
                  "COOKIE_CONSENT_BUTTON"
                )}
                style={{ background: "#1f1f1f" }}
                acceptOnScroll={true}
                buttonStyle={{
                  color: "#000",
                  fontSize: "14px",
                  backgroundColor: "#bcf135",
                  fontFamily: "'Allerta Stencil', sans-serif",
                  borderRadius: "4px",
                }}
                expires={150}
              >
                {translate(this.state.websiteLanguage, "COOKIE_CONSENT_TEXT")}
              </CookieConsent>
            </div>
          );
        }}
      </ClearCache>
    );
  }
}

const App = withRouter(_App);

export default observer(App);
