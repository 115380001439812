import React from "react";
import { translate } from "../utils";
import "./Modal.scss";

const renderSize = (part, sizeSelected, product) => {
  if (
    product._id === "6427ace83ae7e1001417e94d" ||
    product._id === "67617ea9585b140015a9ffb2"
  ) {
    // Cap flex size guide
    if (part === "chest") {
      switch (sizeSelected) {
        case "SM":
          return "55-58";
        case "LXL":
          return "58-61";
        default:
          return "55-58";
      }
    }
  } else if (
    // Cap mesh size guide
    product._id === "6427a68e3ae7e1001417e938" ||
    product._id === "64e510bd0745be001453f48a" ||
    product._id === "66d609418feda60015d5e27e" ||
    product._id === "67110f24a8dc980015f09f36"
  ) {
    return "52-60";
  } else if (product._id === "6212751873870500182108a4") {
    // Hoodie size guide
    if (part === "arms") {
      switch (sizeSelected) {
        case "S":
          return "64";
        case "M":
          return "65";
        case "L":
          return "66";
        case "XL":
          return "68";
        default:
          return "64";
      }
    } else if (part === "chest") {
      switch (sizeSelected) {
        case "S":
          return "104";
        case "M":
          return "108";
        case "L":
          return "114";
        case "XL":
          return "120";
        default:
          return "104";
      }
    } else if (part === "height") {
      switch (sizeSelected) {
        case "S":
          return "68";
        case "M":
          return "70";
        case "L":
          return "72";
        case "XL":
          return "74";
        default:
          return "68";
      }
    }
  } else if (product._id === "610f91209b2dfecff581ada7") {
    if (part === "arms") {
      switch (sizeSelected) {
        case "S":
          return "20";
        case "M":
          return "21";
        case "L":
          return "22";
        case "XL":
          return "23";
        default:
          return "20";
      }
    } else if (part === "chest") {
      switch (sizeSelected) {
        case "S":
          return "96";
        case "M":
          return "102";
        case "L":
          return "106";
        case "XL":
          return "114";
        default:
          return "96";
      }
    } else if (part === "height") {
      switch (sizeSelected) {
        case "S":
          return "71";
        case "M":
          return "75";
        case "L":
          return "75";
        case "XL":
          return "78";
        default:
          return "71";
      }
    }
  } else if (
    product._id === "654501b430f71c00142362bc" ||
    product._id === "65abb6eec27d670014c2566f" ||
    product._id === "66ae875aa341210015813903"
  ) {
    if (part === "arms") {
      switch (sizeSelected) {
        case "XS":
          return "20";
        case "S":
          return "20";
        case "M":
          return "21";
        case "L":
          return "21";
        case "XL":
          return "21";
        default:
          return "20";
      }
    } else if (part === "chest") {
      switch (sizeSelected) {
        case "XS":
          return "90";
        case "S":
          return "96";
        case "M":
          return "100";
        case "L":
          return "104";
        case "XL":
          return "108";
        default:
          return "96";
      }
    } else if (part === "height") {
      switch (sizeSelected) {
        case "XS":
          return "67";
        case "S":
          return "69";
        case "M":
          return "71";
        case "L":
          return "73";
        case "XL":
          return "75";
        default:
          return "69";
      }
    }
  } else if (product._id === "654503f430f71c00142362bd") {
    // Flag shirt workout size guide
    if (part === "arms") {
      switch (sizeSelected) {
        case "S":
          return "19";
        case "M":
          return "19";
        case "L":
          return "21";
        case "XL":
          return "21";
        default:
          return "19";
      }
    } else if (part === "chest") {
      switch (sizeSelected) {
        case "S":
          return "94";
        case "M":
          return "96";
        case "L":
          return "102";
        case "XL":
          return "108";
        default:
          return "94";
      }
    } else if (part === "height") {
      switch (sizeSelected) {
        case "S":
          return "68";
        case "M":
          return "70";
        case "L":
          return "71";
        case "XL":
          return "73";
        default:
          return "68";
      }
    }
  } else if (product._id === "65a6d83390b2c53b2317195a") {
    // MoG Polar size guide
    if (part === "circumference") {
      switch (sizeSelected) {
        case "S":
          return "20";
        case "M":
          return "21";
        case "L":
          return "23";
        case "XL":
          return "25,5";
        default:
          return "21";
      }
    } else if (part === "hand-length") {
      switch (sizeSelected) {
        case "S":
          return "17,5";
        case "M":
          return "18,5";
        case "L":
          return "19,5";
        case "XL":
          return "20,5";
        default:
          return "18,5";
      }
    } else if (part === "glove-length") {
      switch (sizeSelected) {
        case "S":
          return "23";
        case "M":
          return "24";
        case "L":
          return "25";
        case "XL":
          return "26";
        default:
          return "24";
      }
    }
  } else if (
    product._id === "65aac671f4591700148abb6a" ||
    product._id === "65abc4aec27d670014c25671" ||
    product._id === "65abcc8ac27d670014c25677" ||
    product._id === "6661a762000a630015ced2b3"
  ) {
    // MoG other gloves size guide
    if (part === "circumference") {
      switch (sizeSelected) {
        case "S":
          return "17";
        case "M":
          return "20";
        case "L":
          return "21";
        case "XL":
          return "23";
        default:
          return "17";
      }
    } else if (part === "hand-length") {
      switch (sizeSelected) {
        case "S":
          return "17";
        case "M":
          return "18";
        case "L":
          return "18,5";
        case "XL":
          return "19,5";
        default:
          return "17";
      }
    } else if (part === "glove-length") {
      switch (sizeSelected) {
        case "S":
          return "20,5";
        case "M":
          return "21,5";
        case "L":
          return "22,5";
        case "XL":
          return "23";
        default:
          return "20,5";
      }
    }
  } else if (product._id === "66b0599aa3405d00154c8368") {
    // Flag shorts size guide
    if (part === "circumference") {
      switch (sizeSelected) {
        case "S":
          return "76-84";
        case "M":
          return "84-92";
        case "L":
          return "92-100";
        case "XL":
          return "100-108";
        default:
          return "76-84";
      }
    } else if (part === "length") {
      switch (sizeSelected) {
        case "S":
          return "47";
        case "M":
          return "49";
        case "L":
          return "51";
        case "XL":
          return "53";
        default:
          return "47";
      }
    }
  }
};

const Modal = (props) => {
  const setSelectedClassName = (key, variantName) => {
    if (key === 0 && !props.productVariantSelected) {
      return "selected";
    } else if (
      props.productVariantSelected &&
      variantName === props.productVariantSelected
    ) {
      return "selected";
    } else {
      return "unselected";
    }
  };

  const productVariantsAvailable = Object.entries(props.product.variants);

  if (props.product._id === "63c07bca452cdb00167327d3") {
    return (
      <div className="modal" onClick={props.modalClickOutside}>
        <div className="modal-frame info">
          <h3 className="modal-frame-header">
            {translate(props.websiteLanguage, "MORE_INFO_HEADER")}
          </h3>
          <p>{translate(props.websiteLanguage, "MORE_INFO_TEXT")}</p>
          <label
            className="qualification-disclaimer-label"
            htmlFor="qualificationDisclaimer"
          >
            <input
              type="checkbox"
              checked={props.checkboxChecked}
              className="modal-checkbox"
              id="qualificationDisclaimer"
              name="qualificationDisclaimer"
              onChange={() => props.checkboxOnChange()}
            />
            <span className="checkmark" />
            {translate(props.websiteLanguage, "CHECKOUT_FORM_ACCEPT")}
          </label>
          <button
            onClick={props.close}
            className={`proceed-button ${
              props.checkboxChecked ? "visible" : "hidden"
            }`}
          >
            {translate(props.websiteLanguage, "CHECKOUT_FORM_PROCEED")}
          </button>
          <button className="modal-frame-close-button" onClick={props.close}>
            <img alt="close" src="./icons/close-icon.svg" />
          </button>
        </div>
      </div>
    );
  }
  // Caps strapback size guide
  if (
    props.product._id === "6427ace83ae7e1001417e94d" ||
    props.product._id === "67617ea9585b140015a9ffb2"
  ) {
    return (
      <div className="modal" onClick={props.modalClickOutside}>
        <div className="modal-frame">
          <h3 className="modal-frame-header">
            {translate(props.websiteLanguage, "PRODUCT_SIZE_GUIDE")}
          </h3>
          <button className="modal-frame-close-button" onClick={props.close}>
            <img alt="close" src="./icons/close-icon.svg" />
          </button>
          <div className="modal-frame-size-buttons">
            {productVariantsAvailable.map((item, key) => {
              return (
                <button
                  className={`size-button ${setSelectedClassName(
                    key,
                    item[0]
                  )}`}
                  key={key}
                  onClick={() =>
                    props.setSelectedProductVariant(
                      item[0],
                      props.product,
                      productVariantsAvailable
                    )
                  }
                >
                  {item[1].dropdownName}
                </button>
              );
            })}
          </div>
          <div className="size-graphic">
            <img
              className="size-graphic-image"
              alt="cap"
              src="./icons/cap-flex.svg"
            />
            <div className={`chest-circumference cap`}>
              {renderSize("chest", props.productVariantSelected, props.product)}{" "}
              cm
            </div>
          </div>
          <div className="modal-button-row">
            <button onClick={props.close}>
              {translate(props.websiteLanguage, "CLOSE")}
            </button>
          </div>
        </div>
      </div>
    );
  }
  // Caps mesh size guide
  if (
    props.product._id === "6427a68e3ae7e1001417e938" ||
    props.product._id === "64e510bd0745be001453f48a" ||
    props.product._id === "66d609418feda60015d5e27e" ||
    props.product._id === "67110f24a8dc980015f09f36"
  ) {
    return (
      <div className="modal" onClick={props.modalClickOutside}>
        <div className="modal-frame">
          <h3 className="modal-frame-header">
            {translate(props.websiteLanguage, "PRODUCT_SIZE_GUIDE")}
          </h3>
          <button className="modal-frame-close-button" onClick={props.close}>
            <img alt="close" src="./icons/close-icon.svg" />
          </button>
          <div className="size-graphic">
            <img
              className="size-graphic-image"
              alt="cap"
              src="./icons/cap-mesh.svg"
            />
            <div className={`chest-circumference cap`}>
              {renderSize("chest", props.sizeSelectedModal, props.product)} cm
            </div>
          </div>
          <div className="modal-button-row">
            <button onClick={props.close}>
              {translate(props.websiteLanguage, "CLOSE")}
            </button>
          </div>
        </div>
      </div>
    );
  }
  // Gloves size guide
  if (
    props.product._id === "65a6d83390b2c53b2317195a" ||
    props.product._id === "65aac671f4591700148abb6a" ||
    props.product._id === "65abc4aec27d670014c25671" ||
    props.product._id === "65abcc8ac27d670014c25677" ||
    props.product._id === "6661a762000a630015ced2b3"
  ) {
    return (
      <div className="modal" onClick={props.modalClickOutside}>
        <div className="modal-frame">
          <h3 className="modal-frame-header">
            {translate(props.websiteLanguage, "PRODUCT_SIZE_GUIDE")}
          </h3>
          <button className="modal-frame-close-button" onClick={props.close}>
            <img alt="close" src="./icons/close-icon.svg" />
          </button>
          <div className="modal-frame-size-buttons">
            {productVariantsAvailable.map((item, key) => {
              return (
                <button
                  className={`size-button ${setSelectedClassName(
                    key,
                    item[0]
                  )}`}
                  key={key}
                  onClick={() =>
                    props.setSelectedProductVariant(
                      item[0],
                      props.product,
                      productVariantsAvailable
                    )
                  }
                >
                  {item[1].dropdownName}
                </button>
              );
            })}
          </div>
          <div className="size-graphic">
            <img
              className="size-graphic-image"
              alt="glove"
              src="./icons/glove.svg"
            />
            <div className={`circumference glove`}>
              {renderSize(
                "circumference",
                props.productVariantSelected,
                props.product
              )}{" "}
              cm
            </div>
            <div className={`hand-length glove`}>
              {renderSize(
                "hand-length",
                props.productVariantSelected,
                props.product
              )}{" "}
              cm
            </div>
            <div className={`glove-length glove`}>
              {renderSize(
                "glove-length",
                props.productVariantSelected,
                props.product
              )}{" "}
              cm
            </div>
          </div>
          <div className="modal-button-row">
            <button onClick={props.close}>
              {translate(props.websiteLanguage, "CLOSE")}
            </button>
          </div>
        </div>
      </div>
    );
  }
  // Shorts size guide
  if (props.product._id === "66b0599aa3405d00154c8368") {
    return (
      <div className="modal" onClick={props.modalClickOutside}>
        <div className="modal-frame">
          <h3 className="modal-frame-header">
            {translate(props.websiteLanguage, "PRODUCT_SIZE_GUIDE")}
          </h3>
          <button className="modal-frame-close-button" onClick={props.close}>
            <img alt="close" src="./icons/close-icon.svg" />
          </button>
          <div className="modal-frame-size-buttons">
            {productVariantsAvailable.map((item, key) => {
              return (
                <button
                  className={`size-button ${setSelectedClassName(
                    key,
                    item[0]
                  )}`}
                  key={key}
                  onClick={() =>
                    props.setSelectedProductVariant(
                      item[0],
                      props.product,
                      productVariantsAvailable
                    )
                  }
                >
                  {item[1].dropdownName}
                </button>
              );
            })}
          </div>
          <div className="size-graphic">
            <img
              className="size-graphic-image"
              alt="shorts"
              src="./icons/shorts.svg"
            />
            <div className={`circumference shorts`}>
              {renderSize(
                "circumference",
                props.productVariantSelected,
                props.product
              )}{" "}
              cm
            </div>
            <div className={`length shorts`}>
              {renderSize(
                "length",
                props.productVariantSelected,
                props.product
              )}{" "}
              cm
            </div>
          </div>
          <div className="modal-button-row">
            <button onClick={props.close}>
              {translate(props.websiteLanguage, "CLOSE")}
            </button>
          </div>
          <div className="size-up-disclaimer">
            {translate(props.websiteLanguage, "SIZE_UP_DISCLAIMER")}
          </div>
        </div>
      </div>
    );
  }
  // Hoodie and t-shirt size guide
  return (
    <div className="modal" onClick={props.modalClickOutside}>
      <div className="modal-frame">
        <h3 className="modal-frame-header">
          {translate(props.websiteLanguage, "PRODUCT_SIZE_GUIDE")}
        </h3>
        <button className="modal-frame-close-button" onClick={props.close}>
          <img alt="close" src="./icons/close-icon.svg" />
        </button>
        <div className="modal-frame-size-buttons">
          {productVariantsAvailable.map((item, key) => {
            return (
              <button
                className={`size-button ${setSelectedClassName(key, item[0])}`}
                key={key}
                onClick={() =>
                  props.setSelectedProductVariant(
                    item[0],
                    props.product,
                    productVariantsAvailable
                  )
                }
              >
                {item[1].dropdownName}
              </button>
            );
          })}
        </div>
        <div className="size-graphic">
          <img
            className="size-graphic-image"
            alt="t-shirt"
            src={
              props.product._id === "6212751873870500182108a4"
                ? "./icons/hoodie.svg"
                : "./icons/t-shirt.svg"
            }
          />
          <div
            className={`chest-circumference ${
              props.product._id === "6212751873870500182108a4"
                ? "hoodie"
                : "tshirt"
            }`}
          >
            {renderSize("chest", props.productVariantSelected, props.product)}{" "}
            cm
          </div>
          <div
            className={`arm-length ${
              props.product._id === "6212751873870500182108a4"
                ? "hoodie"
                : "tshirt"
            }`}
          >
            {renderSize("arms", props.productVariantSelected, props.product)} cm
          </div>
          <div
            className={`height ${
              props.product._id === "6212751873870500182108a4"
                ? "hoodie"
                : "tshirt"
            }`}
          >
            {renderSize("height", props.productVariantSelected, props.product)}{" "}
            cm
          </div>
        </div>
        <div className="modal-button-row">
          <button onClick={props.close}>
            {translate(props.websiteLanguage, "CLOSE")}
          </button>
        </div>
        <div className="size-up-disclaimer">
          ⓘ {translate(props.websiteLanguage, "SIZE_UP_DISCLAIMER")}
        </div>
      </div>
    </div>
  );
};

export default Modal;
